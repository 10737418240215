// import React, { useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Col, Row, Form, Select, Tooltip, Checkbox, Card } from 'antd';
import styles from './index.module.scss';
import { ArrowRightOutlined, DashOutlined } from '@ant-design/icons';
import { toastText } from 'utils/utils';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

// Define types for form values
interface HeaderMappingFormValues {
	activityDate: any[];
	employee: any[];
	customer: any[];
	class: any[];
	OtherExpensesAccounts: any[];
	salaryExpenseAccounts: any[];
	payrollTaxesAndFringeBenefits: any[];
	hours: any[];
}

// Define types for component props
interface HeaderMappingFormProps {
	handleHeaderMappingForm: (values: HeaderMappingFormValues) => void;
	handleBack: () => void; // Function to handle "Back" button click
	headers: string[]; // Array of available headers for selection
	selectHeadersValues: any;
	isTimeActivityChecked: boolean; // Current selected header values
	selectHeadersValidation: {
		activityDate: boolean;
		employee: boolean;
		customer: boolean;
		class: boolean;
		hours: boolean;
	}; // Validation state for selected headers
	handleSelectChange: (field: string, value: string[]) => void; // Function to handle select changes
	handleCheckboxChange: (value: boolean) => void; // Function to handle select changes
	handleHeadersSubmitButton: () => void; // Function to handle submit button click
}

const HeaderMappingForm: React.FC<HeaderMappingFormProps> = ({
	handleHeaderMappingForm,
	handleBack,
	handleCheckboxChange,
	headers,
	selectHeadersValues,
	isTimeActivityChecked,
	handleSelectChange,
}) => {
	// Validation schema
	const validationSchema = Yup.object().shape({
		// Conditionally apply validation based on isTimeActivityChecked for activityDate
		activityDate: Yup.array().when('isTimeActivityChecked', {
			is: true, // When isTimeActivityChecked is true
			then: (schema) =>
				schema
					.min(1, 'Please select at least one date')
					.required('Please select a date'),
			otherwise: (schema) => schema.notRequired(), // Skip validation if isTimeActivityChecked is false
		}),

		// Conditionally apply validation based on isTimeActivityChecked for hours
		hours: Yup.array().when('isTimeActivityChecked', {
			is: true, // When isTimeActivityChecked is true
			then: (schema) =>
				schema
					.min(1, 'Please enter the hours')
					.required('Please enter the hours'),
			otherwise: (schema) => schema.notRequired(), // Skip validation if isTimeActivityChecked is false
		}),

		// Employee validation is always required
		employee: Yup.array()
			.min(1, 'Please select at least one Field')
			.required('Please select an employee'),

		// Validation for each of the expense account arrays
		OtherExpensesAccounts: Yup.array(),
		salaryExpenseAccounts: Yup.array(),
		payrollTaxesAndFringeBenefits: Yup.array(),

		// Custom validation to ensure only one of the three account arrays has exactly one value
		oneAccountRequired: Yup.mixed().test(
			'only-one-selected',
			'Please select at least one account from the three available account types.',
			function () {
				const {
					OtherExpensesAccounts,
					salaryExpenseAccounts,
					payrollTaxesAndFringeBenefits,
				} = this.parent;

				// Calculate the count of non-empty account arrays
				const count =
					(OtherExpensesAccounts.length > 0 ? 1 : 0) +
					(salaryExpenseAccounts.length > 0 ? 1 : 0) +
					(payrollTaxesAndFringeBenefits.length > 0 ? 1 : 0);

				// If all account arrays are empty
				if (count === 0) {
					toastText(
						'Exactly one of the three accounts must have one selected item.',
						'error'
					);
					return false; // Validation fails if all lengths are zero
				}

				// Check if exactly one of the accounts has a selected item
				const oneSelected =
					(OtherExpensesAccounts?.length >= 1 ? 1 : 0) +
					(salaryExpenseAccounts?.length >= 1 ? 1 : 0) +
					(payrollTaxesAndFringeBenefits?.length >= 1 ? 1 : 0);

				if (oneSelected == 0) {
					toastText(
						'Exactly one of the three accounts must have one selected item.',
						'error'
					);
					return false; // Validation fails if not exactly one is selected
				}

				// If all checks are passed, validation succeeds
				return true;
			}
		),
	});

	const checkValues = () => {
		const missingFields = [];

		if (selectHeadersValues.class.length == 0) {
			missingFields.push('Class');
		}
		if (selectHeadersValues.customer.length == 0) {
			missingFields.push('Customer');
		}

		if (missingFields.length > 0 && isTimeActivityChecked) {
			const missingFieldsMessage = `Warning: The following fields are not mapped: ${missingFields.join(
				', '
			)}`;
			toastText(missingFieldsMessage, 'info');
		}
		handleHeaderMappingForm(selectHeadersValues);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				employee: [], // Ensure all fields are initialized
				salaryExpenseAccounts: [],
				payrollTaxesAndFringeBenefits: [],
				OtherExpensesAccounts: [], // Add this if it's missing
				activityDate: null,
				customer: [],
				isTimeActivityChecked: isTimeActivityChecked,
				...selectHeadersValues, // Spread in other values
			}}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={(
				values: HeaderMappingFormValues,
				{ setSubmitting, resetForm }: FormikHelpers<HeaderMappingFormValues>
			) => {
				console.log(selectHeadersValues);
				setSubmitting(true);
				checkValues();
				setSubmitting(false);
				resetForm({
					values: { ...selectHeadersValues, isTimeActivityChecked: false },
				});
			}}
		>
			{({ handleSubmit, isSubmitting, errors, touched, setFieldValue }) => (
				<Form onFinish={handleSubmit}>
					<Row
						style={{
							margin: '25px 0px 25px 0px',
							padding: '8px',
							backgroundColor: '#f0f0f0',
							borderRadius: '5px',
						}}
					>
						<Col
							span={8}
							style={{ textAlign: 'center', fontWeight: 600 }}
						>
							CAP Headers
						</Col>
						<Col span={6}></Col>
						<Col
							span={10}
							style={{ textAlign: 'center', fontWeight: 600 }}
						>
							File Headers
						</Col>
					</Row>

					<Row
						className="time-sheet-modal__content"
						style={{ marginBottom: '15px' }}
					>
						<Col span={24}>
							<Card
								className={styles['custom-card']}
								bordered={false}
							>
								<Checkbox
									checked={isTimeActivityChecked}
									value={isTimeActivityChecked} // Bind checkbox to state
									onChange={(event: CheckboxChangeEvent) => {
										handleCheckboxChange(!isTimeActivityChecked);
										setFieldValue(
											'isTimeActivityChecked',
											event.target.checked
										); // Update Formik state
									}}
								>
									<span className={styles['highlight-text']}>
										If your file includes employee hours, please check this box
										for time activity-related header mapping, and follow the
										next steps for time activity hours.
									</span>
								</Checkbox>
							</Card>
						</Col>
					</Row>

					{/* Employee Select */}
					<Row
						className="time-sheet-modal__content"
						style={{ marginBottom: '10px' }}
					>
						<Col
							span={8}
							className={styles['customCell']}
						>
							Employee
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DashOutlined style={{ fontSize: '18px', color: '#344735' }} />
							<ArrowRightOutlined
								style={{ fontSize: '18px', color: '#344735' }}
							/>
						</Col>
						<Col span={10}>
							<div style={{ width: '80%', margin: 'auto' }}>
								<Select
									className="pay-period-filter"
									onChange={(value) => {
										setFieldValue('employee', value);
										handleSelectChange('employee', value);
									}}
									style={{ width: '100%' }}
									size="middle"
									placeholder="Select employee fields"
									maxTagCount={'responsive'}
									maxTagPlaceholder={(omittedValues) => {
										return (
											<Tooltip
												title={omittedValues
													.map(({ label }) => label)
													.join(', ')} // Display omitted values in Tooltip
											>
												<span>+ {omittedValues?.length} more...</span>{' '}
											</Tooltip>
										);
									}}
									value={selectHeadersValues.employee}
									mode="multiple"
									status={touched.employee && errors.employee ? 'error' : ''}
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
					</Row>

					<Row
						gutter={16}
						style={{ padding: '10px' }}
						className="time-sheet-modal__content"
					>
						<Col span={8}>
							<Card
								title={
									<div
										style={{
											padding: '0px 0px 0px 10px',
											backgroundColor: '#344735',
											color: '#fff',
										}}
									>
										Salary Expense Accounts
									</div>
								}
								className={`custom-card`}
								bordered={true}
								style={{
									width: '100%',
									marginBottom: '10px',
									height: '180px',
									padding: '10px',
								}}
							>
								<Select
									className={`pay-period-filter custom-select`} // Apply the custom class
									onChange={(value) => {
										setFieldValue('salaryExpenseAccounts', value);
										handleSelectChange('salaryExpenseAccounts', value);
									}}
									style={{ width: '100%', height: '100px' }} // Increased height
									size="middle"
									placeholder="Select Salary Expense Accounts"
									maxTagCount={4}
									maxTagPlaceholder={(omittedValues) => (
										<Tooltip
											title={omittedValues.map(({ label }) => label).join(', ')}
										>
											<span>+ {omittedValues.length} more...</span>
										</Tooltip>
									)}
									value={selectHeadersValues.salaryExpenseAccounts}
									mode="multiple"
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</Card>
						</Col>

						<Col span={8}>
							<Card
								title={
									<div
										style={{
											padding: '0px 0px 0px 10px',
											backgroundColor: '#344735',
											color: '#fff',
										}}
									>
										Payroll Taxes & Fringe Benefits
									</div>
								}
								className={`custom-card`}
								bordered={true}
								style={{
									width: '100%',
									marginBottom: '10px',
									height: '180px',
									padding: '10px',
								}}
							>
								<Select
									className="pay-period-filter custom-select"
									onChange={(value) =>
										handleSelectChange('payrollTaxesAndFringeBenefits', value)
									}
									style={{ width: '100%', height: '100px' }} // Increased height
									size="middle"
									placeholder="Select Payroll Taxes & Fringe Benefits"
									maxTagCount={4}
									maxTagPlaceholder={(omittedValues) => (
										<Tooltip
											title={omittedValues.map(({ label }) => label).join(', ')}
										>
											<span>+ {omittedValues.length} more...</span>
										</Tooltip>
									)}
									allowClear
									value={selectHeadersValues.payrollTaxesAndFringeBenefits}
									mode="multiple"
									// Removed error status
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</Card>
						</Col>

						<Col span={8}>
							<Card
								title={
									<div
										style={{
											padding: '0px 0px 0px 10px',
											backgroundColor: '#344735',
											color: '#fff',
										}}
									>
										Other Expenses{' '}
									</div>
								}
								className={`custom-card`}
								bordered={true}
								style={{
									width: '100%',
									marginBottom: '10px',
									height: '180px',
									padding: '10px',
								}}
							>
								<Select
									className="pay-period-filter custom-select"
									onChange={(value) =>
										handleSelectChange('OtherExpensesAccounts', value)
									}
									style={{ width: '100%', height: '100px' }} // Increased height
									size="middle"
									placeholder="Select Other Expenses"
									maxTagCount={4}
									maxTagPlaceholder={(omittedValues) => (
										<Tooltip
											title={omittedValues.map(({ label }) => label).join(', ')}
										>
											<span>+ {omittedValues.length} more...</span>
										</Tooltip>
									)}
									value={selectHeadersValues.OtherExpensesAccounts}
									mode="multiple"
									// Removed error status
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</Card>
						</Col>
					</Row>

					{/*  Time Activity checkbox start */}

					{isTimeActivityChecked && (
						<>
							<Row style={{ marginBottom: '10px' }}>
								<Col
									span={8}
									className={styles['customCell']}
								>
									Activity Date
								</Col>
								<Col
									span={6}
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<DashOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>
									<ArrowRightOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>
								</Col>
								<Col span={10}>
									<div style={{ width: '80%', margin: 'auto' }}>
										<Select
											className="pay-period-filter"
											onChange={(value) => {
												setFieldValue('activityDate', [value]);
												handleSelectChange('activityDate', [value]);
											}}
											style={{ width: '100%' }}
											size="middle"
											placeholder="Select date field"
											maxTagCount="responsive"
											value={
												selectHeadersValues
													? selectHeadersValues?.activityDate[0]
													: null
											}
											status={
												touched.activityDate && errors.activityDate
													? 'error'
													: ''
											}
											showSearch
										>
											{headers.map((header, index) => (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											))}
										</Select>
									</div>
								</Col>
							</Row>
							<Row
								className="time-sheet-modal__content"
								style={{ marginBottom: '10px' }}
							>
								<Col
									span={8}
									className={styles['customCell']}
								>
									Customer
								</Col>
								<Col
									span={6}
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<DashOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>

									<ArrowRightOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>
								</Col>
								<Col span={10}>
									<div style={{ width: '80%', margin: 'auto' }}>
										<Select
											className="pay-period-filter"
											onChange={(value) => {
												setFieldValue('customer', value);
												handleSelectChange('customer', value);
											}}
											style={{ width: '100%' }}
											size="middle"
											placeholder="Select Customer fields"
											maxTagCount={'responsive'}
											maxTagPlaceholder={(omittedValues) => {
												return (
													<Tooltip
														title={omittedValues
															.map(({ label }) => label)
															.join(', ')} // Display omitted values in Tooltip
													>
														<span>+ {omittedValues?.length} more...</span>{' '}
													</Tooltip>
												);
											}}
											value={selectHeadersValues.customer}
											mode="multiple"
											status={
												touched.customer && errors.customer ? 'error' : ''
											}
											showSearch
										>
											{headers.map((header, index) => (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											))}
										</Select>
									</div>
								</Col>
							</Row>
							<Row
								className="time-sheet-modal__content"
								style={{ marginBottom: '10px' }}
							>
								<Col
									span={8}
									className={styles['customCell']}
								>
									Class
								</Col>
								<Col
									span={6}
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<DashOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>

									<ArrowRightOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>
								</Col>
								<Col span={10}>
									<div style={{ width: '80%', margin: 'auto' }}>
										<Select
											className="pay-period-filter"
											onChange={(value) => handleSelectChange('class', value)}
											style={{ width: '100%' }}
											size="middle"
											placeholder="Select class fields"
											maxTagCount="responsive"
											maxTagPlaceholder={(omittedValues) => {
												return (
													<Tooltip
														title={omittedValues
															.map(({ label }) => label)
															.join(', ')} // Display omitted values in Tooltip
													>
														<span>+ {omittedValues?.length} more...</span>{' '}
													</Tooltip>
												);
											}}
											allowClear
											value={selectHeadersValues.class}
											mode="multiple"
											status={touched.class && errors.class ? 'error' : ''}
											showSearch
										>
											{headers.map((header, index) => (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											))}
										</Select>
									</div>
								</Col>
							</Row>
							<Row
								className="time-sheet-modal__content"
								style={{ marginBottom: '10px' }}
							>
								<Col
									span={8}
									className={styles['customCell']}
								>
									Hours
								</Col>
								<Col
									span={6}
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<DashOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>
									<ArrowRightOutlined
										style={{ fontSize: '18px', color: '#344735' }}
									/>
								</Col>
								<Col span={10}>
									<div
										style={{
											width: '80%',
											margin: 'auto',
										}}
									>
										<Select
											className="pay-period-filter"
											onChange={(value) => {
												setFieldValue('hours', [value]);
												handleSelectChange('hours', [value]);
											}}
											style={{ width: '100%' }}
											size="middle"
											placeholder="Select hours field"
											maxTagCount="responsive"
											value={
												selectHeadersValues
													? selectHeadersValues?.hours[0]
													: null
											}
											status={touched.hours && errors.hours ? 'error' : ''}
											showSearch
										>
											{headers.map((header, index) => (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											))}
										</Select>
									</div>
								</Col>
							</Row>
						</>
					)}
					{/* Activity Date Select */}

					{/*  Time Activity checkbox stop */}
					<Row
						style={{
							marginTop: '20px',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<button
							key="cancel"
							onClick={handleBack}
							className={`${styles['confirm-delete-model__button--cancel']} ${styles['confirm-delete-model__button--btn']}`}
							style={{ marginRight: '8px' }} // Add space between buttons
						>
							Back
						</button>
						<button
							disabled={isSubmitting}
							className="btn-black"
						>
							Next
						</button>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export default HeaderMappingForm;
