/* eslint-disable react/no-unescaped-entities */
import { Col, Modal, Row, Button } from 'antd';
import styles from './index.module.scss';
import { WarningOutlined } from '@ant-design/icons';

interface Props {
	isOpen: boolean;
	onCancel: () => void;
	onProceed: () => void;
	data: any;
	type: string;
	isLoading: boolean;
}

export const InputEditWarningModal = (props: Props) => {
	const { isOpen, onCancel, onProceed, data, type, isLoading } = props;

	const handleProceed = () => {
		onProceed && onProceed();
	};

	return (
		<Modal
			open={isOpen}
			closable={false}
			footer={null}
			className={styles['checkbox-modal profile__popup']}
			width={700}
		>
			<p
				style={{
					fontWeight: 600,
					fontSize: '28px',
					marginBottom: '10px',
					borderBottom: '1px solid #ccc',
					paddingBottom: '10px',
				}}
			>
				<WarningOutlined />
				&nbsp;
				{type === 'Info'
					? 'Value Exceeds Total Limit'
					: type === 'configurationMismatch'
					? 'Configuration Inconsistency'
					: 'Allocation Percentage Update'}
			</p>

			<div style={{ marginBottom: '10px' }}>
				{type === 'Default' && (
					<>
						Are you sure you want to edit this value? It will affect the
						allocation percentages.
					</>
				)}

				{type === 'CostUpdate' && (
					<>
						Changing the value from ${data.originalValue} to ${data.newValue}{' '}
						exceeds the total. Proceeding will impact the employee cost value.
						Are you sure you want to proceed?
					</>
				)}

				{type === 'Info' && (
					<>
						Changing the value from ${data.originalValue} to ${data.newValue}{' '}
						exceeds the total. This value cannot be updated.
					</>
				)}

				{type === 'configurationMismatch' && (
					<>
						As per configuration, Class/Customer is mandatory. Do you want to
						continue?
					</>
				)}
			</div>

			<Row style={{ marginTop: '15px' }}>
				<Col
					xs={24}
					md={8}
					lg={8}
					sm={8}
				>
					<div style={{ display: 'flex', gap: '10px' }}>
						{type === 'configurationMismatch' ? (
							<>
								<Button className={styles['cancel']} onClick={onCancel}>
									No
								</Button>
								<Button
									className={`${styles['save']} ${
										isLoading ? 'pointer-event-none' : ''
									}`}
									onClick={handleProceed}
								>
									{isLoading ? (
										<img
											src="/assets/gifs/loading-black.gif"
											height={40}
											alt="Loading..."
										/>
									) : (
										'Yes'
									)}
								</Button>
							</>
						) : (
							<>
								{type !== 'Info' && (
									<Button className={styles['cancel']} onClick={onCancel}>
										Cancel
									</Button>
								)}
								<Button
									className={`${styles['save']} ${
										isLoading ? 'pointer-event-none' : ''
									}`}
									onClick={handleProceed}
								>
									{isLoading ? (
										<img
											src="/assets/gifs/loading-black.gif"
											height={40}
											alt="Loading..."
										/>
									) : type !== 'Info' ? (
										'Proceed'
									) : (
										'Ok'
									)}
								</Button>
							</>
						)}
					</div>
				</Col>
			</Row>
		</Modal>
	);
};
