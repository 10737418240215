import { useState, useEffect } from 'react';
// import EmployeeCostSelector from '../EmployeeCostSelector';
// import HoursSetup from '../HoursSetup';
import { Modal, Row, Space, Steps, Tabs, Typography } from 'antd';
import { SyncNow } from 'components/Global';
import ExportData from 'components/Global/ExportData';
import PayPeriod from 'components/settings/PayPeriods';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission, toastText } from 'utils/utils';
import DynamicTable from '../Table';
import './index.scss';
import { postApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import { getEmployeeCostAction } from 'redux/action/employeeCostAction';
import { employeeCostSyncDate } from 'redux/slice/companySlice';
import styles from './index.module.scss';
import { useSearchParams } from 'react-router-dom';
import { fetchClosingDateAction } from 'redux/action/closingDateAction';
import { updateNotIncludedEmployeeIds } from 'redux/slice/employeeCostSlice';
import { DownOutlined } from '@ant-design/icons'; // Import DownOutlined for dropdown icon
import { Menu, Dropdown } from 'antd';
import { CloseSvg } from 'utils/svgs';
import UploadFileForm from '../UploadEmployeeCost/UploadFileForm';
import HeaderMappingForm from '../UploadEmployeeCost/HeaderMappingForm';
import DataMappingForm from '../UploadEmployeeCost/DataMappingForm';
import DataReviewTable from '../UploadEmployeeCost/DataReviewTable';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

// import { ImportEmployeeCostCsvModal } from '../ImportEmployeeCostValueModal';
// import { useSelector } from 'react-redux';

const EmployeeCosts = ({
	onChangeKey,
	onChangePayPeriod,
	onChangeCostValue,
}: any) => {
	const [isEmployeeCost, setIsEmployeeCost] = useState('1');
	const [lastSyncTime, setLastSyncTime] = useState(new Date());
	const [syncLoading, setSyncLoading] = useState(false);

	const [queryParams] = useSearchParams();
	const [payPeriodId, setPayPeriodId] = useState<string | null>(
		queryParams.get('payPeriodId') || localStorage.getItem('payPeriod')
	);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [includeInactive, setIncludeInactive] = useState(false);
	const [
		showEmployeeWithAllocationConfig,
		setShowEmployeeWithAllocationConfig,
	] = useState(false);

	const [openEmployeeCostModal, setOpenEmployeeCostModal] = useState(false);

	let items = [];

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const dispatch = useDispatch<AppDispatch>();

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const { data: employeeCosts, notIncludedEmployeeIds } = useSelector(
		(state: any) => state?.employeeCosts
	);

	const employeeLastSyncDate = useSelector(
		(state: any) =>
			state?.companies?.selectedCompanyDetails?.company?.employeeLastSyncDate
	);

	const isViewPayPeriodPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Pay Period',
		permission: ['view'],
	});

	const isAddEmployeeCostPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Employee Cost',
			permission: ['add'],
		}
	);

	const isEditEmployeeCostPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Employee Cost',
			permission: ['edit'],
		}
	);

	const changeEmployeeCost = (isEmployeeCost: string) => {
		setIsEmployeeCost(isEmployeeCost);
	};

	const handleSync = () => {
		setSyncLoading(true);
		postApi(`/employees/sync`, {
			companyId: localStorage.getItem('companyId'),
		})
			.then((data) => {
				dispatch(
					getEmployeeCostAction({
						page: 1,
						limit: 10,
						sort: 'asc',
						payPeriodId: payPeriodId,
						includeInactive,
					})
				);
				dispatch(employeeCostSyncDate());
				dispatch(fetchClosingDateAction({ syncLogs: true }));
				toastText(data.data?.message, 'success');
				setSyncLoading(false);
				// setPayPeriodId(null);
				localStorage.removeItem('payPeriod');
			})
			.catch((err) => {
				toastText(err?.response?.data?.message, 'error');
				setSyncLoading(false);
			});
	};

	const handleInactive = (value: boolean) => {
		setIncludeInactive(value);
	};

	const handleShowEmployeeWithAllocationConfig = (value: boolean) => {
		setShowEmployeeWithAllocationConfig(value);
	};

	const handleEmployeeModalClose = () => {
		setOpenEmployeeCostModal(false);
	};

	useEffect(() => {
		onChangePayPeriod && onChangePayPeriod(payPeriodId);
	}, [payPeriodId]);

	const handleMenuClick = (e: any) => {
		if (e.key === 'import') {
			setOpenEmployeeCostModal(true);
		} else if (e.key === 'upload') {
			openTimeLogUploadModalHandler();
		}
		// Add logic for Upload Employee Cost if needed
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			{employeeCosts.length > 0 ? (
				<>
					<Menu.Item key="import">Import Employee Cost</Menu.Item>
					<Menu.Item key="upload">Upload Employee Cost</Menu.Item>
				</>
			) : (
				<Menu.Item key="upload">Upload Employee Cost</Menu.Item>
			)}
		</Menu>
	);

	const [isTimeLogUploadModalOpen, setIsTimeLogUploadModalOpen] =
		useState(false);

	const openTimeLogUploadModalHandler = async () => {
		setIsTimeLogUploadModalOpen(true);
	};

	const [file, setFile] = useState<any>(null);

	if (isViewPayPeriodPermission) {
		items = [
			{
				key: '1',
				label: 'Employee Cost',
				children: (
					<>
						<div className={styles['employee-costs-export']}>
							<div className={styles['search-filter-second']}>
								{notIncludedEmployeeIds.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button
											onClick={() => {
												dispatch(
													updateNotIncludedEmployeeIds({
														notIncludedEmployeeIds: [],
													})
												);
											}}
											className="btn-black"
										>
											Show All Employees
										</button>
									</Space>
								)}
								{/* {employeeCosts.length > 0 && payPeriodId && ( */}
								{payPeriodId && (
									<Dropdown
										overlay={menu}
										trigger={['click']}
									>
										<button className="btn-black">
											Actions <DownOutlined />
										</button>
									</Dropdown>
								)}
								{(isAddEmployeeCostPermission ||
									isEditEmployeeCostPermission) && (
									<Space className={styles['search-filter']}>
										<SyncNow
											syncDate={lastSyncTime}
											tooltip="Sync Now"
											handleSync={handleSync}
											isLoading={syncLoading}
										/>
									</Space>
								)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<ExportData
											params={{
												search: searchValue,
												payPeriodId: payPeriodId,
												payPeriodData: payPeriods.find(
													(payPeriod: any) => payPeriod.id === payPeriodId
												),
												includeInactive: includeInactive,
											}}
											moduleName="employeeCost"
										/>
									</Space>
								)}
							</div>
						</div>

						{notIncludedEmployeeIds?.length ? (
							<p style={{ padding: '5px 20px', color: '#0000ff' }}>
								Note: The following employees are missing time entries or need
								to be configured with a fixed percentage.
							</p>
						) : null}

						<DynamicTable
							onChangePayPeriod={(value: string | null) => {
								setPayPeriodId(value);
							}}
							onChangeSearchValue={(value: string | null) => {
								setSearchValue(value);
							}}
							handleInactive={handleInactive}
							includeInactive={includeInactive}
							onChangeCostValue={(flag: boolean) => {
								onChangeCostValue && onChangeCostValue(flag);
							}}
							onChangeShowEmployeeWithAllocationConfig={
								handleShowEmployeeWithAllocationConfig
							}
							showEmployeeWithAllocationConfig={
								showEmployeeWithAllocationConfig
							}
							onCloseEmployeeCostModal={handleEmployeeModalClose}
							openEmployeeCostModal={openEmployeeCostModal}
						/>

						{/* {
							openEmployeeCostModal ? (
								<ImportEmployeeCostCsvModal
									open={openEmployeeCostModal}
									onClose={() => handleEmployeeModalClose()}
								/>
							) : null
						} */}
					</>
				),
			},
			{
				key: '2',
				label: 'Pay Period',
				children: <PayPeriod changeEmployeeCost={changeEmployeeCost} />,
			},
		];
	} else {
		items = [
			{
				key: '1',
				label: 'Employee Cost',
				children: (
					<>
						<div className={styles['employee-costs-export']}>
							<div className={styles['search-filter-second']}>
								{notIncludedEmployeeIds.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button
											onClick={() => {
												dispatch(
													updateNotIncludedEmployeeIds({
														notIncludedEmployeeIds: [],
													})
												);
											}}
											className="btn-black"
										>
											Show All Employees
										</button>
									</Space>
								)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button
											onClick={() => setOpenEmployeeCostModal(true)}
											className="btn-black"
										>
											Import Employee Cost
										</button>
									</Space>
								)}
								{(isAddEmployeeCostPermission ||
									isEditEmployeeCostPermission) && (
									<Space className={styles['search-filter']}>
										<SyncNow
											syncDate={lastSyncTime}
											tooltip="Sync Now"
											handleSync={handleSync}
											isLoading={syncLoading}
										/>
									</Space>
								)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<ExportData
											params={{
												search: searchValue,
												payPeriodId: payPeriodId,
												payPeriodData: payPeriods.find(
													(payPeriod: any) => payPeriod.id === payPeriodId
												),
												includeInactive: includeInactive,
											}}
											moduleName="employeeCost"
										/>
									</Space>
								)}
							</div>
						</div>

						{notIncludedEmployeeIds?.length ? (
							<p style={{ padding: '5px 20px', color: '#0000ff' }}>
								Note: The following employees are missing time entries or need
								to be configured with a fixed percentage.
							</p>
						) : null}

						<DynamicTable
							onChangePayPeriod={(value: string | null) => {
								setPayPeriodId(value);
							}}
							onChangeSearchValue={(value: string | null) => {
								setSearchValue(value);
							}}
							handleInactive={handleInactive}
							includeInactive={includeInactive}
							onChangeCostValue={(flag: boolean) => {
								onChangeCostValue && onChangeCostValue(flag);
							}}
							showEmployeeWithAllocationConfig={
								showEmployeeWithAllocationConfig
							}
							onChangeShowEmployeeWithAllocationConfig={
								handleShowEmployeeWithAllocationConfig
							}
							onCloseEmployeeCostModal={handleEmployeeModalClose}
							openEmployeeCostModal={openEmployeeCostModal}
						/>

						{/* {
							openEmployeeCostModal ? (
								<ImportEmployeeCostCsvModal
									open={openEmployeeCostModal}
									onClose={() => handleEmployeeModalClose()}
								/>
							) : null
						} */}
					</>
				),
			},
		];
	}

	const [headers, setHeaders] = useState<any[]>([]);
	const [current, setCurrent] = useState(0);
	const [data, setData] = useState<any[]>([]);
	const [isTimeActivityChecked, setIsTimeActivityChecked] =
		useState<boolean>(false);
	const [fileEmployeeValues, setFileEmployeeValues] = useState<any[]>([]);
	const [selectHeadersValues, setHeadersSelectValues] = useState({
		activityDate: [],
		OtherExpensesAccounts: [],
		salaryExpenseAccounts: [],
		payrollTaxesAndFringeBenefits: [],
		employee: [],
		customer: [],
		class: [],
		hours: [],
	});

	const [fileClassValues, setFileClassValues] = useState<any[]>([]);
	const [fileCustomerValues, setFileCustomerValues] = useState<any[]>([]);

	const { optionData: classList } = useSelector((state: any) => state.class);
	const { data: employees } = useSelector((state: any) => state.employees);
	const { optionData: customerOptions } = useSelector(
		(state: any) => state.customer
	);
	const [historyOfMapping, setHistoryOfMapping] = useState({
		classMapping: [],
		employeeMapping: [],
		customerMapping: [],
	});
	const [checkedTitles, setCheckedTitles] = useState({});

	const getMappingHistory = () => {
		const fetchTimelogMappingHistory = async () => {
			try {
				// Make the API call to get timelog mapping history
				const response = await postApi(
					'/time-activities/timelog-mapping-history',
					{
						companyId: localStorage.getItem('companyId'),
					}
				);

				const mappingData = response.data?.data?.mappingData; // Fallback to empty object if mappingData is undefined

				const {
					headerMapping,
					classMapping,
					employeeMapping,
					customerMapping,
					checkedHeaders,
				} = mappingData.employeeCost ? mappingData.employeeCost : mappingData;

				if (headerMapping) {
					// Create a new object to store the validated header mappings
					const validatedHeaderMapping: any = {};

					// Loop through each key in the `headerMapping`
					for (const key in headerMapping) {
						// Get the value from headerMapping
						const mappingValues = headerMapping[key];

						// Filter the mapping values based on their presence in `headers`
						const filteredValues = mappingValues.filter((value: any) =>
							headers.includes(value)
						);

						// If no values are matched, add the key with an empty array
						// Otherwise, add the key with the matched values
						validatedHeaderMapping[key] =
							filteredValues.length > 0 ? filteredValues : [];
					}

					// Update the state with the validated header mapping
					setHeadersSelectValues(validatedHeaderMapping);
					setCheckedTitles(checkedHeaders);
				}

				// Update historyOfMapping state
				setHistoryOfMapping({
					classMapping: classMapping || [], // Fallback to empty array if classMapping is undefined
					employeeMapping: employeeMapping || [], // Fallback to empty array if employeeMapping is undefined
					customerMapping: customerMapping || [], // Fallback to empty array if customerMapping is undefined
				});
			} catch (error) {
				console.error('Error fetching timelog mapping history:', error);
				// Handle error (optional: display a message to the user)
			}
		};

		fetchTimelogMappingHistory();
	}; // Empty dependency array means this effect runs once on mount

	useEffect(() => {
		getMappingHistory();
	}, [headers]); // Dependency array includes headers

	useEffect(() => {
		getMappingHistory();
	}, []);

	const [selectHeadersValidation, setHeadersSelectValidation] = useState({
		activityDate: false,
		employee: false,
		OtherExpensesAccounts: false,
		salaryExpenseAccounts: false,
		payrollTaxesAndFringeBenefits: false,
		customer: false,
		class: false,
		hours: false,
	});

	const validateHeaders = () => {
		let isValid = true;
		const newValidationState: any = { ...selectHeadersValidation };

		Object.keys(selectHeadersValues).forEach((key: any) => {
			if (
				selectHeadersValues[key as keyof typeof selectHeadersValues].length ===
				0
			) {
				newValidationState[key] = true;
				isValid = false;
			} else {
				newValidationState[key] = false;
			}
		});

		setHeadersSelectValidation(newValidationState);
		return isValid;
	};

	const handleSelectChange = (key: string, value: any) => {
		setHeadersSelectValues((prevValues) => ({
			...prevValues,
			[key]: value,
		}));
		setHeadersSelectValidation((prevValues) => ({
			...prevValues,
			[key]: value.length ? false : true,
		}));
	};

	const handleHeadersSubmitButton = () => {
		const validationPassed = validateHeaders();
		if (validationPassed) {
			console.log('All fields are valid.');
			// Add your logic here when validation passes
		} else {
			console.log('Validation failed.');
			// Add your logic here when validation fails
		}
	};

	const handleTreeSelectChange = (key: string, value: string, data: any) => {
		setFileClassValues((prevValues) =>
			prevValues.map((item) =>
				item.key === key
					? { ...item, selectedValue: value, qboClassName: data[0] }
					: item
			)
		);
	};

	const handleCustomerTreeSelectChange = (
		key: string,
		value: string,
		data: any
	) => {
		setFileCustomerValues((prevValues) =>
			prevValues.map((item) =>
				item.key === key
					? { ...item, selectedValue: value, qboCustomerName: data[0] }
					: item
			)
		);
	};

	const handleEmployeeSelectChange = (
		key: string,
		value: string,
		data: any
	) => {
		console.log('data', data);
		setFileEmployeeValues((prevValues) =>
			prevValues.map((item) =>
				item.key === key
					? {
							...item,
							selectedValue: value,
							qboEmployeeName: data?.children,
					  }
					: item
			)
		);
	};

	const handleBack = () => {
		setHeadersSelectValues(selectHeadersValues);
		setFileEmployeeValues(fileEmployeeValues);

		setCurrent(current - 1);
	};

	const handleContinue = (values: any) => {
		if (!values.file) {
			toastText('Please upload a file first', 'error');
			return;
		}

		const file = values.file;
		const fileSizeInMB = file.size / (1024 * 1024);
		if (fileSizeInMB <= 2) {
			// Updated size check to 2MB
			setFile(file);
			const fileType = file.name.split('.').pop();
			if (fileType) {
				switch (fileType.toLowerCase()) {
					case 'csv':
						processCSV(file);
						break;
					case 'xls':
					case 'xlsx':
						processExcel(file);
						break;
					default:
						toastText('Unsupported file type', 'error');
						setFile(null);
				}
				console.log('values', values, headers, data, selectHeadersValues);
			}
		} else {
			console.log('size limit crossed');
			toastText('File size must be smaller than 2MB', 'error');
			setFile(null);
		}
	};

	const handleImportedEmployeeCost = () => {
		// initialCall();
		// formateTimeLogData();
	};

	const processCSV = (file: any) => {
		const reader = new FileReader();

		reader.onload = (event: any) => {
			const text = event.target.result;

			// Use PapaParse to properly handle CSV parsing, including commas in values
			Papa.parse(text, {
				header: true, // Automatically uses the first row as headers
				skipEmptyLines: true, // Skips empty lines
				complete: (result: any) => {
					if (result && result.data && result.data.length > 0) {
						const headers = Object.keys(result.data[0]);
						const data = result.data;

						setHeaders(headers);
						setData(data);
					} else {
						toastText('CSV file is empty or invalid!', 'error');
					}
				},
				error: () => {
					toastText('Failed to parse the CSV file!', 'error');
				},
			});
		};

		reader.onerror = () => {
			toastText('Failed to read the file!', 'error');
		};

		reader.readAsText(file);
	};

	const processExcel = (file: File) => {
		const reader = new FileReader();

		reader.onload = (event: ProgressEvent<FileReader>) => {
			if (event.target && event.target.result) {
				const data = new Uint8Array(event.target.result as ArrayBuffer);
				const workbook = XLSX.read(data, { type: 'array' });

				// Assuming the first sheet
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];

				// Convert the sheet to JSON
				const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
					header: 1,
				});

				// Find the first non-empty row to use as headers
				let headers: string[] = [];
				let startIndex = 0;

				for (let i = 0; i < jsonData.length; i++) {
					const row = jsonData[i];
					if (
						row.some(
							(cell) => cell !== null && cell !== undefined && cell !== ''
						)
					) {
						headers = row; // Found headers
						startIndex = i + 1; // Start processing data from the next row
						break;
					}
				}

				if (headers.length > 0) {
					// Process rows after headers
					const data = jsonData.slice(startIndex).reduce((acc, row) => {
						// Add your custom condition here to filter out specific rows
						// For example, hide rows where the first column is empty
						if (
							!row.some(
								(cell) => cell !== null && cell !== undefined && cell !== ''
							)
						) {
							return acc; // Skip empty rows
						}

						const rowData = headers.reduce(
							(
								obj: { [key: string]: string },
								header: string,
								index: number
							) => {
								obj[header] = row[index] || '';
								return obj;
							},
							{}
						);
						acc.push(rowData);
						return acc;
					}, [] as { [key: string]: string }[]);

					setHeaders(headers);
					console.log('row data', data);
					setData(data);
				} else {
					toastText('Excel file is empty or invalid!', 'error');
				}
			}
		};

		reader.readAsArrayBuffer(file);
	};

	const handleUploadFileForm = (values: any) => {
		setFile(values.file);
		handleContinue(values);
	};

	const getDataMapping = () => {
		const processValues = (
			fields: any[],
			mapping: any[],
			setFileValues: (value: any[]) => void,
			valueKey: string,
			nameKey: string
		) => {
			const values = data
				.map((item) =>
					fields
						.map((field) => item[field])
						.filter(
							(value) =>
								value !== null &&
								value?.toString().trim() !== '' &&
								value?.toString().trim() !== '_'
						)
						.join('_')
				)
				.filter((value) => value !== '');

			const uniqueValues = Array.from(new Set(values));

			// Initialize values
			const initialValues = uniqueValues
				.filter((value) => value !== '' && value != null)
				.map((value) => ({
					key: value,
					selectedValue: '',
					[nameKey]: '',
				}));

			// Update with mapping if empty
			const updatedValues = initialValues.map((item) => {
				const map = mapping.find((m) => m.key === item.key);
				if (map) {
					return {
						...item,
						selectedValue:
							item.selectedValue === ''
								? map.selectedValue
								: item.selectedValue,
						[nameKey]: item[nameKey] === '' ? map[nameKey] : item[nameKey],
					};
				}
				return item;
			});

			setFileValues(updatedValues);
		};

		// Process class values
		processValues(
			selectHeadersValues.class,
			historyOfMapping.classMapping,
			setFileClassValues,
			'selectedValue',
			'qboClassName'
		);

		// Process employee values
		processValues(
			selectHeadersValues.employee,
			historyOfMapping.employeeMapping,
			setFileEmployeeValues,
			'selectedValue',
			'qboEmployeeName'
		);

		// Process customer values
		processValues(
			selectHeadersValues.customer,
			historyOfMapping.customerMapping,
			setFileCustomerValues,
			'selectedValue',
			'qboCustomerName'
		);
	};

	const handleNext = () => {
		if (current == 1) {
			getDataMapping();
		}
		setCurrent(current + 1);
	};

	const closeMappingModal = () => {
		setData([]);
		setCurrent(0);
		setHeaders([]);
		setFile(null);
		setIsTimeLogUploadModalOpen(false);
		setHeadersSelectValues({
			activityDate: [],
			employee: [],
			OtherExpensesAccounts: [],
			salaryExpenseAccounts: [],
			payrollTaxesAndFringeBenefits: [],
			customer: [],
			class: [],
			hours: [],
		});
	};

	useEffect(() => {
		setLastSyncTime(employeeLastSyncDate);
	}, [employeeLastSyncDate]);

	return (
		<div className="employee-costs-tabs">
			<Tabs
				defaultActiveKey="2"
				items={items}
				activeKey={isEmployeeCost}
				key={isEmployeeCost}
				tabBarStyle={{
					paddingLeft: '20px',
					fontSize: '18px',
				}}
				onChange={(e) => {
					setIsEmployeeCost(e);
					onChangeKey && onChangeKey(e);
				}}
				//indicatorSize={(origin) => origin - 16}
			/>

			{isTimeLogUploadModalOpen ? (
				<>
					<Modal
						open={isTimeLogUploadModalOpen}
						maskClosable={false}
						centered
						onCancel={() => {
							setIsTimeLogUploadModalOpen(false);
							setFile(null);
						}}
						width="70%"
						closable={false}
						footer={null}
						className="CustomMultipleFormModal"
					>
						<Row className={styles['time-sheet-modal__header']}>
							<Typography.Title
								level={2}
								className="text-center"
							>
								Upload Employee Cost
							</Typography.Title>
							<div
								className={styles['time-sheet-modal__header-delete']}
								onClick={closeMappingModal}
							>
								<CloseSvg />
							</div>
						</Row>
						<>
							<Steps current={current}>
								<Steps.Step
									title="Upload File"
									key={0}
								/>
								<Steps.Step
									title="Header Mapping"
									key={1}
								/>
								<Steps.Step
									title="Data Mapping"
									key={2}
								/>
								<Steps.Step
									title="Data Preview"
									key={3}
								/>
							</Steps>
							{current === 0 ? (
								<UploadFileForm
									handleUploadFileForm={handleUploadFileForm}
									handleNext={handleNext}
									file={file}
								/>
							) : current === 1 ? (
								<HeaderMappingForm
									handleHeaderMappingForm={handleNext}
									handleBack={handleBack}
									headers={headers}
									isTimeActivityChecked={isTimeActivityChecked}
									handleCheckboxChange={setIsTimeActivityChecked}
									selectHeadersValues={selectHeadersValues}
									selectHeadersValidation={selectHeadersValidation}
									handleSelectChange={handleSelectChange}
									handleHeadersSubmitButton={handleHeadersSubmitButton}
								/>
							) : current === 2 ? (
								<DataMappingForm
									handleHeaderMappingForm={handleNext}
									handleBack={handleBack}
									isTimeActivityChecked={isTimeActivityChecked}
									headers={headers} // Add missing prop
									fileClassValues={fileClassValues}
									fileEmployeeValues={fileEmployeeValues}
									fileCustomerValues={fileCustomerValues}
									handleTreeSelectChange={handleTreeSelectChange}
									handleEmployeeSelectChange={handleEmployeeSelectChange}
									handleCustomerTreeSelectChange={
										handleCustomerTreeSelectChange
									}
									classList={classList}
									employees={employees}
									customerOptions={customerOptions}
								/>
							) : current === 3 ? (
								<DataReviewTable
									data={data}
									selectHeadersValues={selectHeadersValues}
									handleBack={handleBack}
									isTimeActivityChecked={isTimeActivityChecked}
									handleImportedActivity={handleImportedEmployeeCost}
									fileClassValues={fileClassValues}
									fileEmployeeValues={fileEmployeeValues}
									fileCustomerValues={fileCustomerValues}
									classList={classList}
									employees={employees}
									payPeriodsData={payPeriodId}
									customerOptions={customerOptions}
									closeMappingModal={closeMappingModal}
									checkedHeaders={checkedTitles}
								/>
							) : (
								<div>
									<h1>Error</h1>
								</div>
							)}
						</>
					</Modal>
				</>
			) : null}
		</div>
	);
};

export default EmployeeCosts;
