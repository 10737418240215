import React, { useState, useEffect } from 'react';
import {
	Table,
	Input,
	DatePicker,
	TreeSelect,
	InputNumber,
	Typography,
	Checkbox,
	// Divider,
	// Space,
	// Button,
} from 'antd';
import dayjs from 'dayjs';
import { AddSvg, DeleteActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
// import { PlusOutlined } from '@ant-design/icons';
import { postApi } from 'redux/apis';
import { toastText } from 'utils/utils';
import { Tabs } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { useSearchParams } from 'react-router-dom';
import { historyOfMapping } from 'constants/Data';

interface DataItem {
	key: string;
	date: any;
	employee: any;
	class: any;
	customerName: any;
	className: any;
	customer: any;
	hours: string;
	[key: string]: any; // Add index signature
}

interface HeaderMapping {
	[key: string]: any;
	activityDate: string[];
	employee: string[];
	customer: string[];
	class: string[];
	hours: string[];
}
interface EditableTableProps {
	data: DataItem[];
	selectHeadersValues: HeaderMapping;
	handleBack: () => void;
	closeMappingModal: () => void;
	classList: any[];
	employees: any[];
	customerOptions: any[];
	fileClassValues: {
		key: string;
		selectedValue: string;
		qboClassName: string;
	}[];
	fileEmployeeValues: {
		key: string;
		selectedValue: string;
		qboEmployeeName: string;
	}[];
	fileCustomerValues: {
		key: string;
		selectedValue: string;
		qboCustomerName: string;
	}[];
	payPeriodsData: any;
	isTimeActivityChecked: boolean;
	handleImportedActivity: () => void;
	checkedHeaders: any;
}

const EditableTable: React.FC<EditableTableProps> = ({
	data,
	selectHeadersValues,
	isTimeActivityChecked,
	handleBack,
	closeMappingModal,
	classList,
	employees,
	customerOptions,
	fileClassValues,
	fileEmployeeValues,
	fileCustomerValues,
	// handleImportedActivity,
	payPeriodsData,
	checkedHeaders,
}) => {
	// const [selectedPayPeriod, setSelectedPayPeriod] = useState<string>('');
	const [dataSource, setDataSource] = useState<DataItem[]>([]);
	// const [selectedPayPeriodError, setSelectedPayPeriodError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [queryParams, setQueryParams] = useSearchParams();
	const [checkedTitles, setCheckedTitles] = useState<any>(checkedHeaders);
	const handleEditEmployeeCost = (index: any, key: any, value: any) => {
		const updatedData = [...dataSource]; // Create a copy of the current data
		updatedData[index][key] = value; // Update the specific cell
		updatedData[index].total = calculateEmployeeTotal(updatedData[index]); // Calculate and update total for the specific employee
		setDataSource(updatedData); // Update the data source
	};

	// useEffect(() => {
	// 	const updatedData = dataSource.map((record) => ({
	// 		...record,
	// 		total: calculateEmployeeTotal(record), // Calculate total for each record
	// 	}));
	// 	setDataSource(updatedData); // Update state with recalculated totals
	// 	console.log('dataSource', dataSource);
	// }, [dataSource]);

	useEffect(() => {
		// Flag to track if any record's total has changed
		let hasChanged = false;

		const updatedData = dataSource.map((record) => {
			let recordChanged = false; // Flag to track changes for the current record

			// Loop through checkedTitles and update matching fields
			if (checkedTitles) {
				for (const [title, isChecked] of Object.entries(checkedTitles)) {
					if (isChecked && record[title]) {
						const numericValue = parseFloat(record[title]);
						if (!isNaN(numericValue) && numericValue > 0) {
							record[title] = (-numericValue).toString(); // Make the value negative
							recordChanged = true;
						}
					}
				}
			}

			// Recalculate the total if the record was updated
			const newTotal = calculateEmployeeTotal(record);
			if (newTotal !== record.total) {
				recordChanged = true;
				record.total = newTotal;
			}

			// Mark hasChanged if this record was updated
			if (recordChanged) {
				hasChanged = true;
			}

			return {
				...record,
				checkedHeaders: {
					...checkedTitles,
				},
			}; // Return the updated record
		});

		// Only update the state if any of the records have changed
		if (hasChanged) {
			setDataSource(updatedData);
		}
	}, [dataSource, checkedTitles]);
	// Run this effect only when dataSource changes

	const createNumberInputColumn = (
		title: string,
		dataIndex: string,
		handleEditEmployeeCost: (index: number, key: string, value: number) => void
	) => {
		const [isNegative, setIsNegative] = useState(false);
		const toggleNegative = (title: string, checked: boolean) => {
			// Update the checkbox state
			setIsNegative((prev: any) => ({ ...prev, [title]: checked }));
			// Update the checked state of the specific title
			setCheckedTitles((prev: any) => ({
				...prev,
				[title]: checked,
			}));

			// Update the data source to reflect negative values for the specific column based on title
			setDataSource((prevData) =>
				prevData.map((item) => {
					const updatedItem =
						item[title] !== undefined
							? {
									...item,
									[title]: checked
										? -Math.abs(item[title])
										: Math.abs(item[title]),
							  }
							: item;

					// Add or update the checkedHeaders property to reflect the current checked titles
					return {
						...updatedItem,
						checkedHeaders: {
							...checkedTitles,
							[title]: checked,
						},
					};
				})
			);
		};

		return {
			title: (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<div style={{ textAlign: 'center', marginTop: 5 }}>{title}</div>
					<div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
						<Checkbox
							checked={checkedTitles ? checkedTitles[title] : false}
							onChange={(e) => toggleNegative(title, e.target.checked)}
						/>
					</div>
				</div>
			),
			dataIndex,
			render: (value: number, record: DataItem, index: number) => {
				// Convert the value to negative if the checkbox is checked

				return (
					<InputNumber
						value={value || 0.0}
						precision={2}
						className={value < 0 ? styles.negativeValue : ''}
						// Allow up to 2 decimal places
						/* onChange={(val) => {
							console.log('value', val);
							if (val !== null) {
								// If the checkbox is checked, set the value as negative
								const newValue = isNegative ? -Math.abs(val) : val;
								handleEditEmployeeCost(index, dataIndex, newValue); // Update the value accordingly
							}
						}} */
						formatter={(val) =>
							val !== undefined && val < 0
								? `(${Math.abs(val)})`
								: val?.toString() || ''
						} // Display negative values with parentheses
						parser={(val: any) =>
							val && val.includes('(')
								? -parseFloat(val.replace(/[()]/g, ''))
								: parseFloat(val)
						} // Parse input back to a number
						style={{
							width: '100%',
						}}
						onBlur={(e) => {
							// Get the latest value from the input
							const inputValue = parseFloat(
								e.target.value.replace(/[()]/g, '')
							);
							const latestValue = isNaN(inputValue) ? 0.0 : inputValue;
							const newValue = isNegative
								? -Math.abs(latestValue)
								: latestValue;

							handleEditEmployeeCost(index, dataIndex, newValue); // Update the value accordingly

							// You can use latestValue here as needed
						}}
					/>
				);
			},
		};
	};

	// Dynamic columns based on headers
	const calculateEmployeeTotal = (item: any) => {
		let total = 0;

		// Sum up all relevant fields for the employee
		selectHeadersValues.salaryExpenseAccounts.forEach((account: string) => {
			const key = account;
			if (item[key] !== undefined) {
				total += Number(item[key]) || 0; // Ensure it's a number and add it
			}
		});

		selectHeadersValues.payrollTaxesAndFringeBenefits.forEach(
			(benefit: string) => {
				const key = benefit;
				if (item[key] !== undefined) {
					total += Number(item[key]) || 0; // Ensure it's a number and add it
				}
			}
		);

		selectHeadersValues.OtherExpensesAccounts.forEach((expense: string) => {
			const key = expense;
			if (item[key] !== undefined) {
				total += Number(item[key]) || 0; // Ensure it's a number and add it
			}
		});

		return total; // Return the calculated total
	};

	const EmployeeCostColumns = [
		{
			title: 'Employee',
			dataIndex: 'employee',
			fixed: 'left', // Employee column is fixed
			render: (text: string) =>
				fileEmployeeValues.find((item) => item.selectedValue == text)
					?.qboEmployeeName || (
					<Typography.Text type="danger">Missing Mapping</Typography.Text>
				),
			onCell: () => ({
				style: { borderRight: '2px solid #e8e8e8' },
			}),
			width: 180,
		},
		...selectHeadersValues.salaryExpenseAccounts.map((account: any) =>
			createNumberInputColumn(account, account, handleEditEmployeeCost)
		),
		...selectHeadersValues.payrollTaxesAndFringeBenefits.map((benefit: any) =>
			createNumberInputColumn(benefit, benefit, handleEditEmployeeCost)
		),
		...selectHeadersValues.OtherExpensesAccounts.map((expense: any) =>
			createNumberInputColumn(expense, expense, handleEditEmployeeCost)
		),
		{
			title: 'Total',
			dataIndex: 'total',
			fixed: 'right', // Fix this column to the right
			render: (text: any, record: any) => {
				const total = record.total || 0.0;
				// Format negative totals with parentheses
				const formattedTotal =
					total < 0 ? `(${Math.abs(total).toFixed(2)})` : total.toFixed(2);

				// Apply style to highlight negative totals
				return (
					<span style={{ color: total < 0 ? 'red' : 'inherit' }}>
						{formattedTotal}
					</span>
				);
			},
			onCell: () => ({
				style: { borderLeft: '2px solid #e8e8e8' },
			}),
			width: 100, // Fixed width for total column
		},
	];

	useEffect(() => {
		console.log('data', data);
		const mapData = () => {
			// Create a mapping from raw data keys to the desired keys
			const keyMapping: Record<string, string[]> = Object.keys(
				selectHeadersValues
			).reduce((acc, key) => {
				// Store the array of keys directly
				acc[key] = selectHeadersValues[key];
				return acc;
			}, {} as Record<string, string[]>);
			// Transform the raw data into the desired format

			const customerLookup = fileCustomerValues.reduce(
				(acc: any, item: any) => {
					acc[item.key] = {
						key: item.selectedValue,
						value: item.qboCustomerName,
					};
					return acc;
				},
				{}
			);

			const classLookup = fileClassValues.reduce((acc: any, item: any) => {
				acc[item.key] = {
					key: item.selectedValue,
					value: item.qboClassName,
				};
				return acc;
			}, {});

			const employeeLookup = fileEmployeeValues.reduce(
				(acc: any, item: any) => {
					acc[item.key] = {
						key: item.selectedValue,
						value: item.qboEmployeeName,
					};

					return acc;
				},
				{}
			);

			const getMappedValueForData = (
				keyArray: any,
				record: any,
				lookupArray: any
			) => {
				if (!keyArray || keyArray.length === 0) {
					return { key: '', value: '' };
				} else {
					const concatenatedString = keyArray
						.map((key: any) => record[key])
						.join('_');
					return concatenatedString ? lookupArray[concatenatedString] : '';
				}
			};

			dayjs.extend(customParseFormat);
			dayjs.extend(isBetween);

			const excelDateToJSDate = (serial: number): string => {
				const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel's base date (12/30/1899)

				// Convert serial number to a JS date, adding the days (in ms) to the base date
				const jsDate = new Date(excelEpoch.getTime() + serial * 86400000); // 86400000 ms = 1 day
				jsDate.setUTCHours(0, 0, 0, 0); // Reset time to 00:00:00 to ignore the time part

				// Correct for Excel's leap year bug (serial > 60)
				if (serial > 60) {
					jsDate.setUTCDate(jsDate.getUTCDate() + 1);
				}

				// Adjust for UTC+6:00 timezone
				jsDate.setUTCHours(jsDate.getUTCHours() - 6);

				// Return the date as YYYY-MM-DD without considering local time zone or time shifts
				const year = jsDate.getUTCFullYear();
				const month = (jsDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
				const day = jsDate.getUTCDate().toString().padStart(2, '0'); // Ensure two digits for day

				// Construct and return the formatted date string in YYYY-MM-DD format
				return `${year}-${month}-${day}`;
			};

			const formatDate = (date: string | number | Date): string | null => {
				let parsedDate: dayjs.Dayjs | null = null;

				// Handle Excel serial number
				if (typeof date === 'number') {
					parsedDate = dayjs(excelDateToJSDate(date));
				}

				// Handle Date object
				else if (date instanceof Date) {
					parsedDate = dayjs(date);
				}

				// Handle string formats
				else if (typeof date === 'string') {
					const allowedFormats = [
						'MM-DD-YYYY',
						'MM/DD/YYYY',
						'MM-DD-YY',
						'MM/DD/YY',
						'M-D-YYYY',
						'M/D/YYYY',
						'M-D-YY',
						'M/D/YY',
						'MM-D-YYYY',
						'MM/D/YYYY',
						'M-DD-YYYY',
						'M/DD/YYYY',
						'MM-D-YY',
						'MM/D/YY',
						'M-DD-YY',
						'M/DD/YY',
						// Formats with time
						'MM/DD/YYYY h:mm:ss A',
						'M/D/YYYY h:mm:ss A',
						'MM-DD-YYYY h:mm:ss A',
						'M-D-YYYY h:mm:ss A',
						'MM/DD/YYYY HH:mm:ss',
						'M/D/YYYY HH:mm:ss',
						'MM-DD-YYYY HH:mm:ss',
						'M-D-YYYY HH:mm:ss',
						'YYYY-MM-DDTHH:mm:ssZ',
						'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO 8601 formats
						'YYYY-MM-DD HH:mm:ss',
						'YYYY-MM-DD HH:mm:ss.SSS',
					];

					parsedDate = dayjs(date, allowedFormats, true);

					// Check for single-month date (e.g., "3-2-2024") and exclude it
					if (
						parsedDate.isValid() &&
						(date.match(/^\d{1,2}-\d{1,2}-\d{4}$/) ||
							date.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/))
					) {
						const formattedDate = parsedDate.format('MM/DD/YYYY');
						const isSingleMonthDate =
							formattedDate.startsWith('0') && formattedDate[1] === '/';
						if (isSingleMonthDate) {
							return null;
						}
					}
				}

				return parsedDate && parsedDate.isValid()
					? parsedDate.format('MM/DD/YYYY')
					: null;
			};

			const transformedData = data
				.filter((item: any) => {
					// Check for null values in relevant keys
					const hasValidEmployeeData = keyMapping.employee
						.map((key) => item[key])
						.every((value) => value !== null && value !== undefined);

					const hasValidClassData = keyMapping.class
						.map((key) => item[key])
						.every((value) => value !== null && value !== undefined);

					const hasValidCustomerData = keyMapping.customer
						.map((key) => item[key])
						.every((value) => value !== null && value !== undefined);

					const hasValidDateData = Array.isArray(keyMapping.activityDate)
						? keyMapping.activityDate
								.map((key) => item[key])
								.every(
									(value) =>
										value !== null && value !== undefined && value !== ''
								)
						: item[keyMapping.activityDate] !== null &&
						  item[keyMapping.activityDate] !== undefined &&
						  item[keyMapping.activityDate] !== '';

					return (
						hasValidEmployeeData &&
						hasValidClassData &&
						hasValidCustomerData &&
						hasValidDateData
					);
				})
				.map((item: any, index: number) => {
					const salaryExpenseAccounts = keyMapping.salaryExpenseAccounts.reduce(
						(acc: any, key: string) => {
							acc[key] = String(item[key]) ?? '0.00'; // Using key directly as field name
							return acc;
						},
						{}
					);

					const payrollTaxesAndFringeBenefits =
						keyMapping.payrollTaxesAndFringeBenefits.reduce(
							(acc: any, key: string) => {
								acc[key] = String(item[key]) ?? '0.00'; // Using key directly as field name
								return acc;
							},
							{}
						);

					const otherExpensesAccounts = keyMapping.OtherExpensesAccounts.reduce(
						(acc: any, key: string) => {
							acc[key] = String(item[key]) ?? '0.00'; // Using key directly as field name
							return acc;
						},
						{}
					);

					return {
						key: String(index),
						date: formatDate(item[keyMapping.activityDate.toString()]),
						employee: getMappedValueForData(
							keyMapping.employee,
							item,
							employeeLookup
						).key,
						employeeName: getMappedValueForData(
							keyMapping.employee,
							item,
							employeeLookup
						).value,
						class: getMappedValueForData(keyMapping.class, item, classLookup)
							.key,
						className: getMappedValueForData(
							keyMapping.class,
							item,
							classLookup
						).value,
						customer: getMappedValueForData(
							keyMapping.customer,
							item,
							customerLookup
						).key,
						customerName: getMappedValueForData(
							keyMapping.customer,
							item,
							customerLookup
						).value,
						hours: dayjs()
							.hour(
								Math.floor(
									isNaN(parseFloat(item[keyMapping.hours.toString()]))
										? 0 // Fallback to 0 if the value is NaN
										: parseFloat(item[keyMapping.hours.toString()] || 0)
								)
							)
							.minute(
								Math.round(
									(isNaN(parseFloat(item[keyMapping.hours.toString()]))
										? 0 // Fallback to 0 if the value is NaN
										: parseFloat(item[keyMapping.hours.toString()]) % 1) * 60
								)
							)
							.format('HH:mm'),

						// Merged dynamic account mappings into the final object
						...salaryExpenseAccounts,
						...payrollTaxesAndFringeBenefits,
						...otherExpensesAccounts,
						total: calculateEmployeeTotal(item),
					};
				});
			console.log('transformedData', transformedData);
			setDataSource(transformedData);
			setIsLoading(false);
		};
		mapData();
	}, [
		data,
		selectHeadersValues,
		fileClassValues,
		fileEmployeeValues,
		fileCustomerValues,
	]);

	const handleEdit = (index: number, field: keyof DataItem, value: any) => {
		const newData = dataSource.map((item, i) =>
			i === index ? { ...item, [field]: value } : item
		);
		setDataSource(newData);
	};

	const handleDelete = (index: number) => {
		setDataSource(dataSource.filter((_, i) => i !== index));
	};
	const handleAdd = () => {
		const newKey = `${dataSource.length}`;
		setDataSource([
			{
				key: newKey,
				date: '',
				employee: '',
				class: '',
				className: '',
				customer: '',
				customerName: '',
				hours: '',
			},
			...dataSource,
		]);
	};

	// const handlePayPeriodChange = async (value: string) => {
	// 	setSelectedPayPeriodError(false);
	// 	setSelectedPayPeriod(value);
	// };

	const importEmployeeCost = async () => {
		let validData = false;
		dataSource.forEach((item) => {
			if (!item.employee || !item.employeeName) {
				validData = true;
			}
		});
		setIsLoading(true);

		if (!validData) {
			// List of keys to exclude
			const keysToExclude = [
				'date',
				'class',
				'className',
				'customer',
				'customerName',
				'hours',
				'key',
				'total',
			];

			const mappedEmployeeCost = dataSource.map((item) => {
				// Create a new object excluding the specified keys
				return Object.keys(item)
					.filter((key) => !keysToExclude.includes(key)) // Exclude the keys listed in 'keysToExclude'
					.reduce((newObj: { [key: string]: any }, key) => {
						// Rename 'employeeName' to 'Employee'
						if (key === 'employeeName') {
							newObj['Employee'] = item[key]; // Map 'employeeName' to 'Employee'
						} else {
							newObj[key] = item[key]; // Retain other fields
						}
						return newObj;
					}, {});
			});

			const data = {
				payPeriodId: payPeriodsData,
				companyId: localStorage.getItem('companyId'),
				employeeCostData: mappedEmployeeCost,
				salaryExpenseAccounts: selectHeadersValues.salaryExpenseAccounts,
				payrollTaxesAndFringeBenefits:
					selectHeadersValues.payrollTaxesAndFringeBenefits,
				OtherExpensesAccounts: selectHeadersValues.OtherExpensesAccounts,
			};
			try {
				// Call the API using Axios
				const response = await postApi(
					'/employee-cost/importEmployeeCost',
					data
				);
				// Handle success response
				console.log('Employee cost import successful:', response.data);
				toastText(response.data.message, 'success');

				try {
					const mappingData = {
						headerMapping: selectHeadersValues, // Assuming selectHeadersValues holds the headerMapping
						classMapping: fileClassValues, // Class mapping data from state
						employeeMapping: fileEmployeeValues, // Employee mapping data from state
						customerMapping: fileCustomerValues, // Customer mapping data from state
						companyId: localStorage.getItem('companyId'), // Company ID
						checkedHeaders: checkedTitles,
						type: historyOfMapping.EmployeeCost,
					};
					// Post API request to create or update timelog mapping history
					const historyResponse = await postApi(
						'/time-activities/create-update-timelog-mapping-history',
						mappingData
					);
					console.log(
						'Timelog mapping history created/updated successfully:',
						historyResponse
					);
					toastText('Timelog mapping history updated successfully', 'success');
				} catch (error) {
					console.error(
						'Error creating/updating timelog mapping history:',
						error
					);
				}
				setIsLoading(false);

				closeMappingModal();

				const _query: any = {
					openDrawer: 'true',
					activeKey: 'Configurations',
				};

				if (queryParams.get('payPeriodId')) {
					_query.payPeriodId = payPeriodsData;
				}

				if (queryParams.get('isNewPayPeriod') === 'true') {
					_query.isNewPayPeriod = 'true';
				}

				setQueryParams(_query);

				// Do something with the response, like showing a success message or updating the UI
			} catch (error: any) {
				// Handle error
				console.error(
					'Error importing employee costs:',
					error.response?.data || error.message
				);
				setIsLoading(false);

				toastText(error.response?.data, 'error');
				// Show error notification or handle it appropriately
			}
		} else {
			toastText(
				`Employee mapping missing. Please go back and fill the missing mapping for the employee.`,
				'error'
			);
		}
	};

	// const handleImport = async () => {
	// 	// try {
	// 	// 	setIsLoading(true);
	// 	// 	const payload = {
	// 	// 		companyId: localStorage.getItem('companyId'),
	// 	// 		activities: dataSource.map((activity: any) => ({
	// 	// 			employeeId: activity.employee,
	// 	// 			activityDate: activity?.date
	// 	// 				? dayjs(activity.date).toISOString()
	// 	// 				: '',
	// 	// 			hours: splitTime(activity.hours).hours,
	// 	// 			minute: splitTime(activity.hours).minutes,
	// 	// 			classId: activity.class,
	// 	// 			className: activity.className,
	// 	// 			customerName: activity.customerName,
	// 	// 			customerId: activity.customer,
	// 	// 			SplitTimeActivities: [],
	// 	// 			isCustomerSelected: !activity.class,
	// 	// 			isClassSelected: !activity.customer,
	// 	// 		})),
	// 	// 	};
	// 	// 	const response: any = await postApi(
	// 	// 		'/time-activities/bulk-create-time-activities',
	// 	// 		{
	// 	// 			...payload,
	// 	// 			companyId: localStorage.getItem('companyId'),
	// 	// 		}
	// 	// 	);
	// 	// 	// Handle the response
	// 	// 	if (response.data.statusCode === 201) {
	// 	// 		setIsLoading(false);
	// 	// 		toastText(response.data.message, 'success');
	// 	// 		handleImportedActivity();
	// 	// 		// Handle success response
	// 	// 		if (response.data.data.createdActivities.errors.length > 0) {
	// 	// 			console.warn(
	// 	// 				'Some activities failed validation:',
	// 	// 				response.data.createdActivities.errors
	// 	// 			);
	// 	// 		}
	// 	// 		closeMappingModal();
	// 	// 		// Call the post API for createOrUpdateTimelogMappingHistory
	// 	// 		try {
	// 	// 			const mappingData = {
	// 	// 				headerMapping: selectHeadersValues, // Assuming selectHeadersValues holds the headerMapping
	// 	// 				classMapping: fileClassValues, // Class mapping data from state
	// 	// 				employeeMapping: fileEmployeeValues, // Employee mapping data from state
	// 	// 				customerMapping: fileCustomerValues, // Customer mapping data from state
	// 	// 				companyId: localStorage.getItem('companyId'), // Company ID
	// 	// 			};
	// 	// 			// Post API request to create or update timelog mapping history
	// 	// 			const historyResponse = await postApi(
	// 	// 				'/time-activities/create-update-timelog-mapping-history',
	// 	// 				mappingData
	// 	// 			);
	// 	// 			console.log(
	// 	// 				'Timelog mapping history created/updated successfully:',
	// 	// 				historyResponse
	// 	// 			);
	// 	// 			toastText('Timelog mapping history updated successfully', 'success');
	// 	// 		} catch (error) {
	// 	// 			console.error(
	// 	// 				'Error creating/updating timelog mapping history:',
	// 	// 				error
	// 	// 			);
	// 	// 		}
	// 	// 	}
	// 	// } catch (error: any) {
	// 	// 	console.log(error);
	// 	// 	setIsLoading(false);
	// 	// 	if (error.response.data.responseStatus === 400) {
	// 	// 		console.error('Validation errors:', error.response.data);
	// 	// 		toastText(error.response.data.message, 'error');
	// 	// 	}
	// 	// }
	// 	// Implement import logic here
	// };

	const columns = [
		{
			title: 'Activity Date',
			dataIndex: 'date',
			render: (text: string, record: DataItem, index: number) => (
				<DatePicker
					value={text ? dayjs(text) : null}
					onChange={(date, dateString) => handleEdit(index, 'date', dateString)}
					format="MM-DD-YYYY"
					style={{ width: '100%' }}
				/>
			),
		},
		{
			title: 'Employee',
			dataIndex: 'employee',
			render: (text: string, record: DataItem, index: number) => (
				<TreeSelect
					showSearch
					className={styles['search-filter__filter']}
					style={{ width: '100%' }}
					onChange={(value) => handleEdit(index, 'employee', value)}
					value={
						fileEmployeeValues.find(
							(item) => item.selectedValue == record.employee
						)?.selectedValue || ''
					} // Value should be the selected value
					placeholder="Select Employee"
					allowClear={false}
					treeDefaultExpandAll
					size="large"
					treeData={employees.map((employee) => ({
						value: employee.id,
						title: employee.fullName,
						children: [],
					}))}
					filterTreeNode={(inputValue, treeNode) =>
						treeNode.props.title
							.toLowerCase()
							.includes(inputValue.toLowerCase())
					}
					// dropdownRender={(menu) => (
					// 	<>
					// 		{menu}
					// 		<Divider style={{ margin: '8px 0' }} />
					// 		<Space style={{ padding: '0 8px 4px' }}>
					// 			<Button
					// 				type="text"
					// 				icon={<PlusOutlined />}
					// 			>
					// 				Add new Employee
					// 			</Button>
					// 		</Space>
					// 	</>
					// )}
				/>
			),
		},
		{
			title: 'Class',
			dataIndex: 'class',
			render: (text: string, record: DataItem, index: number) => (
				<TreeSelect
					showSearch
					className={styles['search-filter__filter']}
					style={{ width: '100%' }}
					onChange={(value) => handleEdit(index, 'class', value)}
					value={
						fileClassValues.find((item) => item.selectedValue == record.class)
							?.selectedValue || record.class
					} // Value should be the selected value
					placeholder="Select Class"
					allowClear={false}
					treeDefaultExpandAll
					size="large"
					treeData={classList} // Ensure you have the `classOptions` data
					filterTreeNode={(inputValue, treeNode) =>
						treeNode.props.title
							.toLowerCase()
							.includes(inputValue.toLowerCase())
					}
					// dropdownRender={(menu) => (
					// 	<>
					// 		{menu}
					// 		<Divider style={{ margin: '8px 0' }} />
					// 		<Space style={{ padding: '0 8px 4px' }}>
					// 			<Button
					// 				type="text"
					// 				icon={<PlusOutlined />}
					// 			>
					// 				Add new Class
					// 			</Button>
					// 		</Space>
					// 	</>
					// )}
				/>
			),
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			render: (text: string, record: DataItem, index: number) => (
				<TreeSelect
					showSearch
					className={styles['search-filter__filter']}
					style={{ width: '100%' }}
					onChange={(value) => handleEdit(index, 'customer', value)}
					value={
						fileCustomerValues.find(
							(item) => item.selectedValue == record.customer
						)?.selectedValue || record.customer
					} // Value should be the selected value
					placeholder="Select Customer"
					allowClear={false}
					treeDefaultExpandAll
					size="large"
					treeData={customerOptions} // Ensure you have the `customerOptions` data
					filterTreeNode={(inputValue, treeNode) =>
						treeNode.props.title
							.toLowerCase()
							.includes(inputValue.toLowerCase())
					}
					// dropdownRender={(menu) => (
					// 	<>
					// 		{menu}
					// 		<Divider style={{ margin: '8px 0' }} />
					// 		<Space style={{ padding: '0 8px 4px' }}>
					// 			<Button
					// 				type="text"
					// 				icon={<PlusOutlined />}
					// 			>
					// 				Add new Customer
					// 			</Button>
					// 		</Space>
					// 	</>
					// )}
				/>
			),
		},
		{
			title: 'Hours',
			dataIndex: 'hours',
			render: (text: string, record: DataItem, index: number) => (
				<Input
					value={text}
					onChange={(e) => handleEdit(index, 'hours', e.target.value)}
				/>
			),
		},
		{
			title: <div style={{ textAlign: 'center' }}>Action</div>,
			render: (_: any, record: DataItem, index: number) => (
				<div
					className={styles['action-svg']}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => handleDelete(index)}
					>
						<DeleteActionSvg />
					</span>
					<p className="delete-text">Delete</p>
				</div>
			),
		},
	];

	return (
		<div>
			{/* Tabs to switch between Employee Cost and Time Activity */}
			<p
				style={{
					color: '#818181',
					padding: '10px',
					fontSize: '14px',
					textAlign: 'right',
				}}
			>
				Check to toggle the value between positive and negative.
			</p>
			<Tabs defaultActiveKey="1">
				<Tabs.TabPane
					tab="Employee Cost"
					key="1"
				>
					<Table
						columns={EmployeeCostColumns}
						dataSource={dataSource} // Your data here
						scroll={{ x: 'max-content', y: 400 }} // Scroll vertically
						pagination={false}
						loading={isLoading}
					/>
				</Tabs.TabPane>
				{/* Conditionally show Time Activity tab if isTimeActivityChecked is true */}
				{isTimeActivityChecked && (
					<Tabs.TabPane
						tab="Time Activity"
						key="2"
					>
						<>
							<div className={styles.container}>
								<button
									className={`btn-black`}
									style={{ height: '45px' }}
									onClick={handleAdd}
								>
									<AddSvg />
									<p>Add Time Activity</p>
								</button>
							</div>
							<div className={styles['table-container']}>
								<Table
									className={styles['dynamic-table']}
									dataSource={dataSource}
									columns={columns}
									rowKey="key"
									loading={isLoading}
									pagination={false}
									scroll={{ y: 'calc(100vh - 500px)' }}
								/>
							</div>
						</>
					</Tabs.TabPane>
				)}
			</Tabs>

			<div
				style={{
					marginTop: '20px',
					display: 'flex',
					justifyContent: 'start',
					alignItems: 'center',
				}}
			>
				<button
					key="cancel"
					onClick={handleBack}
					className={`${styles['confirm-delete-model__button--cancel']} ${styles['confirm-delete-model__button--btn']}`}
					style={{ marginRight: '8px' }} // Add space between buttons
				>
					Back
				</button>
				<button
					className="btn-black"
					onClick={importEmployeeCost}
					disabled={isLoading}
				>
					Import
				</button>
			</div>
		</div>
	);
};

export default EditableTable;

// function splitTime(time: string) {
// 	const [hours, minutes] = time.split(':').map(String);
// 	return { hours, minutes };
// }
