import type { DatePickerProps } from 'antd';
import { DatePicker, Input, Modal, Table, Row, Col, Tag } from 'antd';
import styles from './index.module.scss';
import './index.scss';
import { PreviewJournalProps } from './types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getApi, postApi } from 'redux/apis';
import { base64ToBlob, checkPermission, toastText } from 'utils/utils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { previewJournalAction } from 'redux/action/journalAction';
import { NumericFormat } from 'react-number-format';
import { CloseSvg } from 'utils/svgs';
import { EJournalStatus } from 'constants/Data';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const PreviewJournal = (props: PreviewJournalProps) => {
	const navigate = useNavigate();

	const {
		handleClosePreviewJournal,
		isPreviewJournalOpen,
		payPeriodLastDate,
		payPeriodId,
		payPeriodData,
		getJournalListingData,
	} = props;

	const { Column } = Table;

	const startDate = moment(payPeriodData?.startDate).format('MM/DD/YYYY');
	const endDate = moment(payPeriodData?.endDate).format('MM/DD/YYYY');

	const [isAccountClosed, setIsAccountClosed] = useState<boolean>(false);

	const [, setAutoJournalNo] = useState(1);

	const dispatch = useDispatch<AppDispatch>();

	const { journalData: journalEntries, isLoading } = useSelector(
		(state: any) => state?.journal
	);

	const [journalData, setJournalData] = useState<any>({
		journalDate: dayjs(payPeriodLastDate),
		journalNumber: 1,
		notes: '',
		qboJournalNumber: null,
	});

	const [attachmentData, setAttachmentData] = useState<string>('');
	const [publishIsLoading, setPublishIsLoading] = useState<boolean>(false);
	const [draftIsLoading, setDraftIsLoading] = useState<boolean>(false);
	const [journalExists, setJournalExists] = useState<boolean>(false);

	const { closingDate } = useSelector((state: any) => state?.closingDate);

	const { configurations: configurationsData } = useSelector(
		(state: any) => state.companies
	);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const isJournalEntriesPermissionEdit = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journals Entries',
			permission: ['edit'],
		}
	);

	const isJournalEntriesPermissionAdd = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journals Entries',
			permission: ['add'],
		}
	);

	useEffect(() => {
		if (payPeriodId) {
			fetchJournalDetails();
			fetchJournalData();
			createCostAllocationPdf();
		}
	}, []);

	useEffect(() => {
		//

		if (journalEntries && journalEntries.length) {
			let notes = ``;
			notes += `${startDate} - ${endDate}`;

			const employeeNames: any[] = [''];

			journalEntries.forEach((e: any) => {
				if (!employeeNames.includes(e.employeeName)) {
					employeeNames.push(e.employeeName);
				}
			});
			employeeNames.shift();
			notes += ` ${employeeNames.join(', ')}`;

			// if (journalData && !journalData.notes) {
			setJournalData((prevData: any) => {
				return {
					...prevData,
					notes: notes,
				};
			});
			// }
		}
	}, [journalEntries]);

	useEffect(() => {
		if (payPeriodData && closingDate) {
			if (dayjs(payPeriodData.endDate) < dayjs(closingDate).endOf('day')) {
				setIsAccountClosed(true);
			}
		}
	}, [payPeriodData, closingDate]);

	const dateChangeHandler: DatePickerProps['onChange'] = (date: any) => {
		setJournalData((prevData: any) => {
			return {
				...prevData,
				journalDate: date,
			};
		});
	};

	// const journalNoChangeHandler = (number: any) => {
	// 	setJournalData((prevData: any) => {
	// 		return {
	// 			...prevData,
	// 			journalNumber: number,
	// 		};
	// 	});
	// };

	const notesHandler = (value: any) => {
		setJournalData((prevData: any) => {
			return {
				...prevData,
				notes: value.target.value,
			};
		});
	};

	const handleSubmit = async (status: number) => {
		try {
			if (journalEntries && journalEntries.length > 0) {
				if (status === EJournalStatus.PUBLISHED) {
					setPublishIsLoading(true);
				} else if (status === EJournalStatus.DRAFT) {
					setDraftIsLoading(true);
				}
				await postApi('/journal/create', {
					companyId: localStorage.getItem('companyId'),
					payPeriodId: payPeriodId,
					date: journalData?.journalDate,
					qboJournalNo: String(journalData?.journalNumber),
					status: status,
					notes: journalData?.notes,
					dateString: moment(journalData?.journalDate?.$d).format('YYYY-MM-DD'),
				});

				toastText(
					`Journal ${journalExists ? 'edited' : 'created'} successfully`,
					'success'
				);
				setPublishIsLoading(false);
				setDraftIsLoading(false);
				if (getJournalListingData) {
					getJournalListingData();
				}
				handleClosePreviewJournal();
			}
		} catch (err: any) {
			setPublishIsLoading(false);
			setDraftIsLoading(false);
			toastText(err?.response?.data?.message, 'error');
			// handleClosePreviewJournal();
		}
	};

	const createCostAllocationPdf = async () => {
		const query = {
			companyId: localStorage.getItem('companyId'),
			payPeriodId: payPeriodId,
		};

		const response = await getApi('/cost-allocation/export-pdf', query);
		const blob = base64ToBlob(response.data.data, 'application/pdf');
		const blobUrl = URL.createObjectURL(blob);
		setAttachmentData(blobUrl);
	};

	const fetchJournalData = async () => {
		try {
			await dispatch(
				previewJournalAction({
					payPeriodId,
				})
			);
		} catch (err) {
			toastText('Something went wrong in preview journal', 'error');
		}
	};

	const fetchJournalDetails = async () => {
		try {
			const response: any = await getApi('/journal/by-payPeriod', {
				companyId: localStorage.getItem('companyId'),
				payPeriodId,
			});
			if (response.data.data) {
				setJournalData((prevData: any) => {
					return {
						...prevData,
						journalDate: dayjs(response?.data?.data?.date),
						journalNumber: response?.data?.data?.qboJournalNo,
						notes: response?.data?.data?.notes,
						status: response?.data?.data?.status,
						qboJournalNumber: response?.data?.data?.qboJournalNo,
					};
				});
				setJournalExists(true);
			} else {
				const latestJournalNo: any = await getApi('/journal/latest-no', {
					companyId: localStorage.getItem('companyId'),
				});
				if (latestJournalNo.data.data) {
					setJournalData({
						...journalData,
						journalNumber: latestJournalNo.data.data.latestNo,
					});
					setAutoJournalNo(latestJournalNo.data.data.latestNo);
				}
				setJournalExists(false);
			}
		} catch (err: any) {
			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			} else {
				toastText('Something went wrong in preview journal', 'error');
			}
		}
	};

	return (
		<Modal
			open={isPreviewJournalOpen}
			onCancel={handleClosePreviewJournal}
			className="preview-container"
			closable={false}
			style={{
				borderRadius: 0,
			}}
			footer={[]}
		>
			<div>
				<Row className={styles['preview-container__top']}>
					<div className={styles['preview-container__top-title']}>
						<b>Journal Preview</b>
					</div>
					<div
						className={styles['preview-container__top-close']}
						onClick={handleClosePreviewJournal}
					>
						<CloseSvg />
					</div>
				</Row>
				<div className={styles['preview-container__content']}>
					<div className={styles['preview-container__content--header']}>
						<p className={styles['preview-container__content--header-title']}>
							Journal Entry{' '}
							{journalData?.qboJournalNumber && journalData?.status === 1
								? `#${journalData?.qboJournalNumber}`
								: ``}
						</p>
						<div className={styles['preview-container__content--header-date']}>
							Journal Date:{' '}
							<DatePicker
								onChange={dateChangeHandler}
								value={journalData?.journalDate}
								format={'MM-DD-YYYY'}
								allowClear={false}
								disabled={
									(journalExists && !isJournalEntriesPermissionEdit) ||
									(!journalExists && !isJournalEntriesPermissionAdd) ||
									isAccountClosed
								}
							/>
						</div>
						{/* workig but hide in ui */}
						{/* <div
							className={styles['preview-container__content--header-number']}
						>
							Journal No:
							<InputNumber
								value={journalData?.journalNumber}
								onChange={journalNoChangeHandler}
								onBlur={(e: any) => {
									const regex = /^(0*[1-9][0-9]*(\.\d+)?)$/;

									if (!regex.test(e.target.value)) {
										setJournalData((prevData: any) => {
											return {
												...prevData,
												journalNumber: autoJournalNo,
											};
										});
										return;
									}
								}}
								disabled={
									(journalExists && !isJournalEntriesPermissionEdit) ||
									(!journalExists && !isJournalEntriesPermissionAdd) ||
									isAccountClosed
								}
							/>
						</div> */}
					</div>
					<div className={styles['preview-container__content--table']}>
						<Table
							dataSource={journalEntries}
							pagination={false}
							style={{ borderRadius: 0 }}
							loading={isLoading}
							rowKey={(record: any) => {
								return record.key;
							}}
							rowClassName={(record: any) => {
								if (record.account === 'Total') {
									return 'journal-total-row';
								}
								return '';
							}}
						>
							<Column
								title="#"
								dataIndex="id"
								key="id"
								className="bg-white journal-dashed-border"
							/>
							<Column
								title="Account"
								dataIndex="account"
								key="account"
								className="bg-white journal-dashed-border"
								render={(value: string | number, record: any) => {
									return (
										<div>
											{value} <br />
											{record?.ruleName ? (
												<Tag color="#108ee9">{record?.ruleName}</Tag>
											) : null}
										</div>
									);
								}}
							/>
							<Column
								title="Debit(+)"
								dataIndex="debit"
								key="debit"
								className="bg-white journal-dashed-border"
								align="right"
								render={(value: string | number, record: any) => {
									return (
										<NumericFormat
											prefix={record.account === 'Total' ? '$' : ''}
											value={value}
											thousandSeparator=","
											decimalScale={
												configurationsData?.decimalToFixedAmount || 2
											}
											fixedDecimalScale={true}
											displayType="text"
											renderText={(value) => (
												<p
													className={styles['dynamic-table-total']}
													style={{
														textAlign: 'right',
													}}
												>
													{value ?? 0}
												</p>
											)}
										/>
									);
								}}
							/>
							<Column
								title="Credit(-)"
								dataIndex="credit"
								key="credit"
								className="bg-white journal-dashed-border credit-font"
								align="right"
								render={(value: string | number, record: any) => {
									return (
										<NumericFormat
											prefix={record.account === 'Total' ? '$' : ''}
											value={value}
											thousandSeparator=","
											decimalScale={
												configurationsData?.decimalToFixedAmount || 2
											}
											fixedDecimalScale={true}
											displayType="text"
											renderText={(value) => (
												<p
													className={styles['dynamic-table-total']}
													style={{
														textAlign: 'right',
													}}
												>
													{value ?? 0}
												</p>
											)}
										/>
									);
								}}
							/>
							<Column
								title="Employee Name"
								dataIndex="employeeName"
								key="employeeName"
								className="bg-white journal-dashed-border"
							/>
							<Column
								title="Customer Name"
								dataIndex="customer"
								key="customer"
								className="bg-white journal-dashed-border"
							/>
							<Column
								title="Class"
								dataIndex="class"
								key="class"
								className="bg-white"
							/>
						</Table>
					</div>
					<div className={styles['preview-container__content--footer']}>
						<Row gutter={32}>
							<Col
								xs={16}
								sm={16}
								md={16}
								lg={16}
							>
								<div
									className={styles['preview-container__content--footer-notes']}
								>
									<label htmlFor="my-textarea">Notes:</label>
									<TextArea
										rows={3}
										className={
											styles['preview-container__content--footer-textarea']
										}
										value={journalData?.notes}
										onChange={notesHandler}
										disabled={
											(journalExists && !isJournalEntriesPermissionEdit) ||
											(!journalExists && !isJournalEntriesPermissionAdd) ||
											isAccountClosed
										}
									/>
								</div>
							</Col>
							<Col
								xs={8}
								sm={8}
								md={8}
								lg={8}
								className={styles['preview-container__content--footer-upload']}
							>
								<p>Attachment: </p>
								<a
									href={attachmentData}
									target="_blank"
									rel="noreferrer"
								>
									Cost Allocation Report of the Pay Period : {startDate} -{' '}
									{endDate}
								</a>
							</Col>
						</Row>
						<div
							className={styles['preview-container__content--footer-buttons']}
							key={Math.random()}
						>
							{!(
								(journalExists && !isJournalEntriesPermissionEdit) ||
								(!journalExists && !isJournalEntriesPermissionAdd) ||
								isAccountClosed
							) ? (
								<>
									<button
										className={`${
											styles[
												'preview-container__content--footer-buttons-button'
											]
										} ${
											(isLoading || publishIsLoading || draftIsLoading) &&
											'pointer-event-none'
										}`}
										onClick={() => handleSubmit(EJournalStatus.PUBLISHED)}
										disabled={
											isLoading ||
											(journalExists && !isJournalEntriesPermissionEdit) ||
											(!journalExists && !isJournalEntriesPermissionAdd)
										}
									>
										{publishIsLoading ? (
											<img
												src="/assets/gifs/loading-black.gif"
												height={40}
											/>
										) : (
											'Save & Publish'
										)}
									</button>
									{!journalExists ||
									(journalExists &&
										journalData.status != EJournalStatus.PUBLISHED) ? (
										<button
											className={`${
												styles[
													'preview-container__content--footer-buttons-button'
												]
											} ${
												(isLoading ||
													publishIsLoading ||
													draftIsLoading ||
													(journalExists && !isJournalEntriesPermissionEdit) ||
													(!journalExists && !isJournalEntriesPermissionAdd)) &&
												'pointer-event-none'
											}`}
											onClick={() => handleSubmit(EJournalStatus.DRAFT)}
											disabled={
												isLoading ||
												(journalExists && !isJournalEntriesPermissionEdit) ||
												(!journalExists && !isJournalEntriesPermissionAdd)
											}
										>
											{draftIsLoading ? (
												<img
													src="/assets/gifs/loading-black.gif"
													height={40}
												/>
											) : (
												'Save as Draft'
											)}
										</button>
									) : null}
								</>
							) : null}
							<button
								className={
									styles['preview-container__content--footer-buttons-cancel']
								}
								onClick={handleClosePreviewJournal}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PreviewJournal;
