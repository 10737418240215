// import React, { useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Col, Row, Form, Select } from 'antd';
import styles from './index.module.scss';
import { ArrowRightOutlined, DashOutlined } from '@ant-design/icons';
import { toastText } from 'utils/utils';

// Define types for form values
interface HeaderMappingFormValues {
	activityDate: any[];
	employee: any[];
	customer: any[];
	class: any[];
	hours: any[];
}

// Define types for component props
interface HeaderMappingFormProps {
	handleHeaderMappingForm: (values: HeaderMappingFormValues) => void;
	handleBack: () => void; // Function to handle "Back" button click
	headers: string[]; // Array of available headers for selection
	selectHeadersValues: any; // Current selected header values
	selectHeadersValidation: {
		activityDate: boolean;
		employee: boolean;
		customer: boolean;
		class: boolean;
		hours: boolean;
	}; // Validation state for selected headers
	handleSelectChange: (field: string, value: string[]) => void; // Function to handle select changes
	handleHeadersSubmitButton: () => void; // Function to handle submit button click
}

// Validation schema
const validationSchema = Yup.object().shape({
	activityDate: Yup.array()
		.min(1, 'Please select at least one Field')
		.required('Please select a date'), // Ensure 'activityDate' is a string and required
	employee: Yup.array()
		.min(1, 'Please select at least one Field') // Ensures 'employee' is an array with at least one item
		.required('Please select an employee'), // Ensure 'employee' is a string and required
	hours: Yup.array()
		.min(1, 'Please select at least one Field')
		.required('Please enter the hours'), // Ensure 'hours' is a number and required
});

const HeaderMappingForm: React.FC<HeaderMappingFormProps> = ({
	handleHeaderMappingForm,
	handleBack,
	headers,
	selectHeadersValues,
	handleSelectChange,
}) => {
	const checkValues = () => {
		const missingFields = [];

		if (selectHeadersValues.class.length == 0) {
			missingFields.push('Class');
		}
		if (selectHeadersValues.customer.length == 0) {
			missingFields.push('Customer');
		}
		if (missingFields.length > 0) {
			const missingFieldsMessage = `Warning: The following fields are not mapped: ${missingFields.join(
				', '
			)}`;
			toastText(missingFieldsMessage, 'info');
		}
		handleHeaderMappingForm(selectHeadersValues);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={selectHeadersValues}
			validationSchema={validationSchema}
			onSubmit={(
				values: HeaderMappingFormValues,
				{ setSubmitting, resetForm }: FormikHelpers<HeaderMappingFormValues>
			) => {
				setSubmitting(true);
				checkValues();
				setSubmitting(false);
				resetForm();
			}}
		>
			{({ handleSubmit, isSubmitting, errors, touched, setFieldValue }) => (
				<Form onFinish={handleSubmit}>
					<Row
						style={{
							margin: '25px 0px 25px 0px',
							padding: '8px',
							backgroundColor: '#f0f0f0',
							borderRadius: '5px',
						}}
					>
						<Col
							span={8}
							style={{ textAlign: 'center', fontWeight: 600 }}
						>
							CAP Headers
						</Col>
						<Col span={6}></Col>
						<Col
							span={10}
							style={{ textAlign: 'center', fontWeight: 600 }}
						>
							File Headers
						</Col>
					</Row>

					{/* Activity Date Select */}
					<Row style={{ marginBottom: '10px' }}>
						<Col
							span={8}
							className={styles['customCell']}
						>
							Activity Date
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DashOutlined style={{ fontSize: '18px', color: '#344735' }} />
							<ArrowRightOutlined
								style={{ fontSize: '18px', color: '#344735' }}
							/>
						</Col>
						<Col span={10}>
							<div style={{ width: '80%', margin: 'auto' }}>
								<Select
									className="pay-period-filter"
									onChange={(value) => {
										setFieldValue('activityDate', [value]);
										handleSelectChange('activityDate', [value]);
									}}
									style={{ width: '100%' }}
									size="middle"
									placeholder="Select date field"
									maxTagCount="responsive"
									value={
										selectHeadersValues
											? selectHeadersValues?.activityDate[0]
											: null
									}
									status={
										touched.activityDate && errors.activityDate ? 'error' : ''
									}
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
					</Row>

					{/* Employee Select */}
					<Row
						className="time-sheet-modal__content"
						style={{ marginBottom: '10px' }}
					>
						<Col
							span={8}
							className={styles['customCell']}
						>
							Employee
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DashOutlined style={{ fontSize: '18px', color: '#344735' }} />
							<ArrowRightOutlined
								style={{ fontSize: '18px', color: '#344735' }}
							/>
						</Col>
						<Col span={10}>
							<div style={{ width: '80%', margin: 'auto' }}>
								<Select
									className="pay-period-filter"
									onChange={(value) => {
										setFieldValue('employee', value);
										handleSelectChange('employee', value);
									}}
									style={{ width: '100%' }}
									size="middle"
									placeholder="Select employee fields"
									maxTagCount={'responsive'}
									value={selectHeadersValues.employee}
									mode="multiple"
									status={touched.employee && errors.employee ? 'error' : ''}
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
					</Row>

					<Row
						className="time-sheet-modal__content"
						style={{ marginBottom: '10px' }}
					>
						<Col
							span={8}
							className={styles['customCell']}
						>
							Customer
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DashOutlined style={{ fontSize: '18px', color: '#344735' }} />

							<ArrowRightOutlined
								style={{ fontSize: '18px', color: '#344735' }}
							/>
						</Col>
						<Col span={10}>
							<div style={{ width: '80%', margin: 'auto' }}>
								<Select
									className="pay-period-filter"
									onChange={(value) => {
										setFieldValue('customer', value);
										handleSelectChange('customer', value);
									}}
									style={{ width: '100%' }}
									size="middle"
									placeholder="Select customer fields"
									maxTagCount={'responsive'}
									value={selectHeadersValues.customer}
									mode="multiple"
									status={touched.customer && errors.customer ? 'error' : ''}
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
					</Row>

					<Row
						className="time-sheet-modal__content"
						style={{ marginBottom: '10px' }}
					>
						<Col
							span={8}
							className={styles['customCell']}
						>
							Class
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DashOutlined style={{ fontSize: '18px', color: '#344735' }} />

							<ArrowRightOutlined
								style={{ fontSize: '18px', color: '#344735' }}
							/>
						</Col>
						<Col span={10}>
							<div style={{ width: '80%', margin: 'auto' }}>
								<Select
									className="pay-period-filter"
									onChange={(value) => handleSelectChange('class', value)}
									style={{ width: '100%' }}
									size="middle"
									placeholder="Select class fields"
									maxTagCount={'responsive'}
									value={selectHeadersValues.class}
									mode="multiple"
									status={touched.class && errors.class ? 'error' : ''}
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
					</Row>

					{/* Hours Select */}
					<Row
						className="time-sheet-modal__content"
						style={{ marginBottom: '10px' }}
					>
						<Col
							span={8}
							className={styles['customCell']}
						>
							Hours
						</Col>
						<Col
							span={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<DashOutlined style={{ fontSize: '18px', color: '#344735' }} />
							<ArrowRightOutlined
								style={{ fontSize: '18px', color: '#344735' }}
							/>
						</Col>
						<Col span={10}>
							<div
								style={{
									width: '80%',
									margin: 'auto',
								}}
							>
								<Select
									className="pay-period-filter"
									onChange={(value) => {
										setFieldValue('hours', [value]);
										handleSelectChange('hours', [value]);
									}}
									style={{ width: '100%' }}
									size="middle"
									placeholder="Select hours field"
									maxTagCount="responsive"
									value={
										selectHeadersValues ? selectHeadersValues?.hours[0] : null
									}
									status={touched.hours && errors.hours ? 'error' : ''}
									showSearch
								>
									{headers.map((header, index) => (
										<Select.Option
											value={header}
											key={index}
										>
											{header}
										</Select.Option>
									))}
								</Select>
							</div>
						</Col>
					</Row>

					<Row
						style={{
							marginTop: '20px',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<button
							key="cancel"
							onClick={handleBack}
							className={`${styles['confirm-delete-model__button--cancel']} ${styles['confirm-delete-model__button--btn']}`}
							style={{ marginRight: '8px' }} // Add space between buttons
						>
							Back
						</button>
						<button
							disabled={isSubmitting}
							className="btn-black"
						>
							Next
						</button>
					</Row>
				</Form>
			)}
		</Formik>
	);
};

export default HeaderMappingForm;
