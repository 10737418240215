import { Col, Modal, Row, Button } from 'antd';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
	isOpen: boolean;
	data: any;
	onCancel: () => void;
	onProceed: () => void;
	isLoading: boolean;
}

export const HeaderValueToggleModal = (props: Props) => {
	const { isOpen, onCancel, onProceed, data, isLoading } = props;

	const handleProceed = () => {
		onProceed && onProceed();
	};

	return (
		<Modal
			open={isOpen}
			closable={false}
			footer={null}
			className={styles['checkbox-modal profile__popup']}
			width={500}
		>
			<p
				style={{
					fontWeight: 600,
					fontSize: '28px',
					marginBottom: '10px',
					borderBottom: '1px solid #ccc',
					paddingBottom: '10px',
				}}
			>
				<InfoCircleOutlined />
				&nbsp; Confirm Conversion
			</p>
			<div style={{ marginBottom: '10px' }}>
				All values under the header{' '}
				<span style={{ fontWeight: 'bold' }}>{data.header} </span>will be
				converted to <span style={{ fontWeight: 'bold' }}>{data.text}</span>{' '}
				amounts.
			</div>

			<Row style={{ marginTop: '15px' }}>
				<Col xs={24} md={8} lg={8} sm={8}>
					<div style={{ display: 'flex', gap: '10px' }}>
						<Button className={styles['cancel']} onClick={onCancel}>
							Cancel
						</Button>

						<Button
							className={`${styles['save']} ${
								isLoading ? 'pointer-event-none' : ''
							}`}
							onClick={handleProceed}
						>
							{isLoading ? (
								<img src="/assets/gifs/loading-black.gif" height={40} />
							) : (
								'Proceed'
							)}
						</Button>
					</div>
				</Col>
			</Row>
		</Modal>
	);
};
